import React from 'react';
import Article from '../Article'
import Footer from '../Footer'
import '../../App.css';

export default function Artikull() {
    return (
        <>
            <Article />
            <Footer />
        </>
    );
}