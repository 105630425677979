import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Sidebar from './Sidebar'
import './Article.css';

function Article(match) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  let params = useParams(match);

  useEffect(() => {
    fetch(`https://akdc.gov.al/admin/articles/${params.articleId}`)
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
        console.log(`https://akdc.gov.al/admin/articles/${params.articleId}`)
      })
      .catch((error) => console.log(error));
  }, [params.articleId]);


  return(
      <div className="floatContainer">
        {!isLoading && (
        <>  
        <div className="article">
            <div className="articleContainer">
                <div className="articleHeader">
                    <span className="articleCategory">{data.category.name}</span>
                    <h1 className="articleTitle">{data.title}</h1>
                    <span className="postedBy">{data.category.updated_at}</span>
                </div>

               {/*<div className="articleImageContainer">
                    <img src={`https://api.techbox.solutions${data.images[0].url}`} alt='' />
                  </div>*/}

                <div className="articleContent">
                    <p>{data.content}</p>
                </div>
            </div>
        </div>
        <div className="floatSidebar">
            <Sidebar
              path={`https://akdc.gov.al${data.files[0].url}`}
              name={data.files[0].name}
            />
         </div>
         </>
        )}
      </div>

   )
 }

export default Article;
