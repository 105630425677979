import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Rreth nesh</h2>
            <Link to='/projekte'>Projekte</Link>
            <Link to='/sherbime'>Sherbime</Link>
            <Link to='/struktua'>Struktura</Link>
            <Link to='/legjislacioni'>Legjislacioni</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Kontakt</h2>
            <h4>Nr. kontakti</h4>
            <h4>Adresë email</h4>
            <h4>Adresë fizike</h4>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              AKDC
            </Link>
          </div>
          <small className='website-rights'>Agjencia Kombëtare e Duhan Cigares © 2020</small>
          </div>
      </section>
    </div>
  );
}

export default Footer;