import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import '../Cards.css';
import CardItem from '../CardItem';
import '../../App.css';

function Kategoria(match) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState();
    let params = useParams(match);
  
    useEffect(() => {
      fetch(`https://akdc.gov.al/admin/categories/${params.articleCategory}`)
        .then((res) => res.json())
        .then((response) => {
          setData(response);
          setIsLoading(false);
          console.log(`https://akdc.gov.al/admin/categories/${params.articleCategory}`)
        })
        .catch((error) => console.log(error));
    }, [params.articleCategory]);

  
    if(data) {
        return (
            <div className='cards'>
                <h1>{data.name}</h1>
            <div className='cards__container'>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                {!isLoading && data.articles.map((item, index) => (    
                <CardItem key={index}
                    //src={`https://akdc.gov.al/admin${item.images[0].url}`}
                    text={item.title}
                    label={data.name}
                    path={`/${data.name}/artikull/${item.id}`}
                />))}
                </ul>
            </div>
            </div>
        </div>
        );
    }
    return (
        <div>Nuk ka artikuj në këtë kategori</div>
    );
  }
  
  export default Kategoria;