import React from 'react';
import {Button} from './Button';
import '../App.css'
import './HeroSection.css'

function HeroSection() {
    return (
        <div className='hero-container'>
            <p>Agjencia Kombëtare e Duhan Cigareve</p>
            <div className='hero-btn'>
                <Button className='btn' byttonStyle='btn--outline' buttonSize='btn--large'>
                    Lexo me shumë
                </Button>
            </div>
        </div>
    )
}

export default HeroSection;
