import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const[click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [categories, setCategories] = useState([]);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    useEffect(() => {
        showButton();
    }, [])

    useEffect(() => {
		fetch("https://akdc.gov.al/admin/categories")
			.then((response) => response.json())
			.then((data) => {
				setCategories(data) // new
			})
	}, [])

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>AKDC</Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>Kreu</Link>
                    </li>
                    {categories.map((item, index) => (
                    <li className='nav-item' key={index}>
                        <Link to={`/categories/${item.id}`} className='nav-links' onClick={closeMobileMenu}>{item.name}</Link>
                    </li>))}
                </ul>
                {button}
            </nav>
            
        </>
    )
}

export default Navbar;
