import React, {useState, useEffect} from 'react';
import './Cards.css';
import CardItem from './CardItem';


function Cards() {
  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
	fetch("https://akdc.gov.al/admin/articles")
			.then((response) => response.json())
			.then((data) => {
        setArticles(data); // new
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [])

  return (
    <div className='cards'>
      <h1>Më të rejat</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            {!isLoading && articles.slice(articles.length - 2, articles.length).map((item, index) => (
            <CardItem key={index}
              //src={`https://api.techbox.solutions${item.images[0].url}`}
              text={item.title}
              label={item.category.name}
              path={`/${item.category.name}/artikull/${item.id}`}
            />))}
          </ul>
          <ul className='cards__items'>
            {articles.slice(articles.length - 5, articles.length - 2).map((item, index) => (
            <CardItem key={index}
              //src={`https://akdc.gov.al/admin${item.images[0].url}`}
              text={item.title}
              label={item.categories[0].name}
              path={`/${item.categories[0].name}/artikull/${item.id}`}
            />))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
