import React from 'react';
import { Link } from 'react-router-dom';
import './Cards.css';

function Sidebar(props) {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <div className='cards__item__info'>Shkarkoni dokumentacionin e plotë
              <h5 className='cards__item__text'>{props.name}</h5>
            </div>
        </Link>
      </li>
    </>
  );
}

export default Sidebar;